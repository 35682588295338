export const categories = {
  docs: [
    {
      label: 'Getting Started',
      subtitle: 'Create and adjust map'
    },
    {
      label: 'Add Data',
      subtitle: 'Uploading and importing'
    },
    {
      label: 'Layers',
      subtitle: 'Styling and management'
    },
    {
      label: 'Sharing and Collaboration',
      subtitle: 'Invite users and publish'
    },
    // {
    //   label: 'Analysis',
    //   subtitle: 'Operations and analyses'
    // },
    {
      label: 'Integrations',
      subtitle: 'Queries of data providers'
    }
  ],
  guides: [
    // {
    //   label: 'Atlas 101: Introduction',
    //   subtitle: 'Learn the basics'
    // },
    {
      label: 'Renewable Energy',
      subtitle: 'Evaluate potential sites for renewable energy'
    },
    {
      label: 'Real Estate',
      subtitle: 'Property and investments'
    },
    {
      label: 'Healthcare',
      subtitle: 'Healthcare services'
    }
  ]
}
