import React, { useState, useEffect } from 'react'
import styles from './Article.module.scss'
import GridItem from './GridItem'
import { Link } from 'gatsby'
import Categories from './Categories'
import Content from '../singlePost/Content'
import Button from '../common/Button'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'

const Article = ({
  title,
  subtitle,
  image,
  body,
  tag,
  embedLink,
  heroType,
  next
}) => {
  const getTitle = tag => {
    if (tag === 'docs') {
      return 'Documentation'
    } else if (tag === 'guides') {
      return 'Guides'
    }
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.headerContent}>
            <div className={styles.heading}>
              <div className={styles.breadcrumb}>
                <Link to="/help/"> Help</Link> /{' '}
                <Link to={`/help/${tag}/`}>{getTitle(tag)}</Link> /{' '}
                <Link to={`/${tag}/${_.kebabCase(title)}/`}>{title}</Link>
              </div>
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </div>
          </div>
          <div className={styles.featuredContent}>
            {heroType === 'embed' ? (
              <iframe
                src={embedLink}
                width="100%"
                height="600"
                frameBorder={0}
                style={{
                  borderRadius: '10px',
                  border: '1px solid var(--midGrey)'
                }}
              ></iframe>
            ) : image ? (
              <div className={styles.featuredImage}>
                <img src={image} alt={title} />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={styles.articleContent}>
            <article itemScope itemType="http://schema.org/BlogPosting">
              <Content source={body} />
              <h2>Need more help?</h2>
              <p>
                Still have questions? We're here to help! Reach out to our
                support team at <a href="mailto:help@atlas.co">help@atlas.co</a>{' '}
                and we'll get back to you as soon as possible.
              </p>
            </article>
            {next && (
              <div className={styles.nextArticle}>
                <div className={styles.featuredImage}>
                  <img src={next.featuredImage} alt={next.title} />
                </div>
                <div className={styles.nextDetails}>
                  <div>
                    <h5>Up next</h5>
                    <h3>{next.title}</h3>
                    <p>{next.description}</p>
                    <Button
                      variant="minimal"
                      rightIcon={<ArrowRightIcon width={20} />}
                      href={next.link}
                    >
                      Read more
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Article
