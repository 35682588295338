import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Layout from '../components/common/Layout'
import Article from '../components/help/Article'
import { categories } from '../data/categories'

export const SingleArticle = ({ data }) => {
  const title = data.page.frontmatter.title
  const subtitle = data.page.frontmatter.subtitle
  const image = data.page.frontmatter.featuredImage
  const heroType = data.page.frontmatter.heroType
  const embedLink = data.page.frontmatter.embedLink
  const body = data.page.html
  const next = data.page.frontmatter.next

  const isGuides = Boolean(
    data.guides.nodes.find(
      node => node.frontmatter.title === data.page.frontmatter.title
    )
  )

  return (
    <Layout
      title={title}
      meta={{
        description: subtitle,
        title: title,
        type: 'website'
      }}
    >
      <main>
        <Article
          title={title}
          subtitle={subtitle}
          image={image}
          body={body}
          tag={isGuides ? 'guides' : 'docs'}
          embedLink={embedLink}
          heroType={heroType}
          next={next}
        />
      </main>
    </Layout>
  )
}

export default SingleArticle

export const pageQuery = graphql`
  ## Query for SingleArticle data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleArticle($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        subtitle
        featuredImage
        embedLink
        heroType
        next {
          title
          description
          featuredImage
          link
        }
      }
      parent {
        ... on File {
          relativeDirectory
        }
      }
    }

    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/docs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          date(formatString: "MMMM D, YYYY")
        }
        fields {
          slug
        }
        parent {
          ... on File {
            relativeDirectory
          }
        }
      }
    }

    guides: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/guides/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          date(formatString: "MMMM D, YYYY")
        }
        fields {
          slug
        }
        parent {
          ... on File {
            relativeDirectory
          }
        }
      }
    }
  }
`
